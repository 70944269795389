<template>
  <div style="display: grid; gap: 1.5rem">
    <FormLabel>
      <p>{{ $t('form.title') }}</p>
      <select id="title" v-model="forms.traveler.titleId">
        <option v-for="title in titles" :key="title.id" :value="title.id">
          {{ title.title }}
        </option>
      </select>
    </FormLabel>
    <FormLabel>
      <p>{{ $t('form.first-name') }}</p>
      <FormField
        v-model="forms.traveler.firstName"
        :pattern="REGEX.NAME"
        minlength="2"
        required
        @valid="forms.vset.traveler.firstName"
      />
    </FormLabel>
    <FormLabel>
      <p>{{ $t('form.last-name') }}</p>
      <FormField
        v-model="forms.traveler.lastName"
        :pattern="REGEX.NAME"
        minlength="2"
        required
        @valid="forms.vset.traveler.lastName"
      />
    </FormLabel>
    <FormLabel>
      <p>{{ $t('form.dob') }}</p>
      <FormField
        :value="displayDate"
        required
        @valid="forms.vset.traveler.dateOfBirth"
        @focus="e => $refs.datePicker.open(e)"
      />
    </FormLabel>
    <datetime
      ref="datePicker"
      v-model="forms.traveler.dateOfBirth"
      :max-datetime="dp.max"
      :min-datetime="dp.min"
      auto
      value-zone="UTC"
      zone="UTC"
    />
    <!-- <FormLabel>
      <p>{{ $t('form.email') }}</p>
      <FormField
        v-model="forms.traveler.email"
        type="email"
        required
        @valid="forms.vset.traveler.email"
      />
    </FormLabel> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { REGEX } from '../../constants/REGEX'
import { createDatepicker } from '../../other/utils/datepickerUtil'
import FormField from './FormField.vue'
import FormLabel from './FormLabel.vue'

export default {
  name: 'TravelerForm',
  components: { FormLabel, FormField },
  props: { traveler: Object },
  created() {
    this.$watch(
      () => this.forms.traveler.dateOfBirth,
      value => {
        this.forms.vset.traveler.dateOfBirth(!!value)
      },
      { immediate: true },
    )
    if (this.traveler) {
      Object.keys(this.forms.traveler).forEach(key => {
        if (key !== 'dateOfBirth') this.forms.traveler[key] = this.traveler[key]
      })
    }
    this.forms.traveler.dateOfBirth = this.dp.getValue(
      this.traveler?.dateOfBirth ?? '',
    )
  },
  data() {
    const dateMin =
      { adult: 100, child: 12, infant: 2 }[this.travelerType] || 100
    const dateMax = { adult: 18, child: 2, infant: 0 }[this.travelerType] || 0
    const dateInitial =
      { adult: 30, child: 6, infant: 1 }[this.travelerType] || 30
    return {
      dp: createDatepicker({
        min: dateMin,
        max: dateMax,
        initial: dateInitial,
      }),
      REGEX,
      forms: {
        traveler: {
          titleId: undefined,
          firstName: '',
          lastName: '',
          dateOfBirth: '',
          // email: '',
        },
      },
    }
  },
  watch: {
    forms: {
      deep: true,
      immediate: true,
      handler() {
        this.$emit('valid', this.forms?.vget?.traveler?.form)
      },
    },
    'forms.traveler.titleId'(value) {
      this.forms.vset.traveler.titleId(!!value)
    },
  },
  computed: {
    ...mapState('TitleModule', ['titles']),
    travelerType: vm =>
      vm.traveler?.travelerType?.toLowerCase?.() ?? vm.$route.params.type,
    displayDate: vm => vm.dp.formatValue(vm.forms.traveler.dateOfBirth),
  },
  methods: {
    createTraveler() {
      this.$emit('create-start')
      this.$store
        .dispatch('TravelerModule/createTraveler', this.forms.traveler)
        .then(
          traveler => this.$emit('create-success', traveler),
          () => this.$emit('create-error'),
        )
        .finally(() => this.$emit('create-end'))
    },
    updateTraveler() {
      const traveler = { ...this.traveler, ...this.forms.traveler }
      this.$emit('update-start')
      this.$store
        .dispatch('TravelerModule/updateTraveler', traveler)
        .then(
          traveler => this.$emit('update-success', traveler),
          () => this.$emit('update-error'),
        )
        .finally(() => this.$emit('update-end'))
    },
    deleteTraveler() {
      this.$emit('delete-start')
      this.$store
        .dispatch('TravelerModule/deleteTraveler', {
          id: this.traveler.id,
        })
        .then(
          () => this.$emit('delete-success'),
          () => this.$emit('delete-error'),
        )
        .finally(() => this.$emit('delete-end'))
    },
  },
}
</script>

<style scoped lang="scss"></style>
